<template>
  <div class="container">
    <el-form :model="form"
             :rules="rules"
             label-position="top"
             ref="form">
      <el-form-item label="活动名称"
                    class="width300"
                    prop="title">
        <el-input type="text"
                  placeholder="请输入活动名称"
                  v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="活动区域">
        <Area v-model="areaAddress"
              @change="areaChange" />
      </el-form-item>
      <el-form-item label="总金额"
                    class="width300"
                    prop="total_money">
        <el-input type="text"
                  placeholder="请输入总预算"
                  v-model.number="form.total_money"></el-input>
      </el-form-item>
      <el-form-item label="起止时间"
                    prop="started_at">
        <el-date-picker v-model="dateTime"
                        type="datetimerange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        @change="dateChange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="邮政活动ID"
                    class="width300"
                    prop="act_id">
        <el-input type="text"
                  placeholder="请输入邮政活动ID"
                  v-model="form.act_id"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary"
                   @click="submitForm('form')">立即{{form.id?'修改':'创建'}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { setReward, getReward } from '@/api/market'
import Area from '@/components/Area'
export default {
  name: 'SetReward',
  data () {
    return {
      selectLoading: false,
      options: [],
      areaAddress: {},
      dateTime: [],
      form: {
        title: '',
        total_money: 0,
        list_province: '',
        list_city: '',
        list_district: '',
        list_net: '',
        started_at: '',
        ended_at: '',
        act_id: ''
      },
      rules: {
        title: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        wx_stock_id: [
          { required: true, message: '请输入微信批次号', trigger: 'blur' }
        ],
        act_id: [
          { required: true, message: '请输入邮政活动id', trigger: 'blur' }
        ],
        started_at: [
          { required: true, message: '请选择起止时间', trigger: 'change' }
        ]
      }
    }
  },
  components: {
    Area
  },
  methods: {
    dateChange (e) {
      if (e) {
        this.form.started_at = e[0]
        this.form.ended_at = e[1]
      } else {
        this.form.started_at = ''
        this.form.ended_at = ''
      }
    },
    areaChange (address) {
      const { form } = this
      form.list_province = address.province
      form.list_city = address.city
      form.list_district = address.district
      form.list_net = address.net
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const form = Object.assign({}, this.form)
          form.total_money = form.total_money * 100
          const res = await setReward(form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.id ? '修改' : '创建'}成功`,
              type: 'success'
            })
            this.$router.push('rewards')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          this.$message.error('表单信息填写有误, 请检查')
        }
      })
    },
    async getReward (id) {
      const res = await getReward({
        id: id
      })
      if (res.meta.code === 0) {
        const activity = res.data.activity
        this.form = activity
        this.form.activity_id = id
        this.areaAddress = {
          province: activity.list_province,
          city: activity.list_city,
          district: activity.list_district,
          net: activity.list_net
        }
        this.form.total_money = this.form.total_money / 100
        this.dateTime = [activity.started_at, activity.ended_at]
      }
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.getReward(this.$route.query.id)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
